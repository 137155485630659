import React from 'react'

import '../privacy_hero/PrivacyHero.css'

const TermsHero = () => {
  return (
    <>
      <div className='custom-container privacy-policy-container'>
        <h1>Terms of Use</h1>
        <p>
          Every user of this website must adhere to these policies which guide
          user activities while using this website.
        </p>
      </div>
    </>
  )
}

export default TermsHero
