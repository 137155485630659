import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  const date = new Date();
  const getYear = date.getFullYear();

  return (
    <div className="fc">
      <footer className="footer-container">
        <div className="footer-copyrights">
          <div className="website">
            <a href="https://www.krizbeatzmusicacademy.com">krizbeatzmusicacademy.com</a>
          </div>
          <div className="vr"></div>
          <div className="copyright">
            <p className="mb-0">&copy; Copyright {getYear}</p>
          </div>
        </div>
        <div className="footer-social-links">
          <a
            href="https://www.youtube.com/@krizbeatz1106/featured"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-youtube"></i>
          </a>
          <a
            href="https://www.instagram.com/krizbeatz_/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a
            href="https://twitter.com/KrizBeatz_"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-twitter"></i>
          </a>
          <a
            href="https://facebook.com/KrizbeatzTheDrummerBoy?_rdc=1&_rdr"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-facebook-f"></i>
          </a>
          <a
            href="https://www.tiktok.com/@krizbeatz_"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-tiktok"></i>
          </a>
        </div>
      </footer>
      <div className="divider my-4"></div>
      <div className="footer-links">
        <ul>
          <Link to="/terms"><li>Terms of Use</li></Link>
          <Link to="/privacy-policy"><li>Privacy Policy</li></Link>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
