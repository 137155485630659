import React from "react";
import "./standalone-courses.css";

const StandaloneCourses = () => {
  return (
    <div className="custom-container">
      <h1>Standalone Courses</h1>
      <div className="standalone-contents mt-5">
        <div className="sa-card p-4">
          <div className="sa-card-title mb-2">
            <h4>Afrobeat Recording & Engineering</h4>
            <h3>700USD</h3>
          </div>
          <p>
            Learn to record professional music from the comfort of your bedroom.
          </p>
          <a href="https://payments.krizbeatzmusicacademy.com/#/standalone-are">
            <button className="button sa-enrol-btn mt-4">Enrol</button>
          </a>
        </div>
        <div className="sa-card p-4">
          <div className="sa-card-title mb-2">
            <h4>Afrobeat Mixing & Mastering</h4>
            <h3>1,000USD</h3>
          </div>
          <p>
            Learn to record professional music from the comfort of your bedroom.
          </p>
          <a href="https://payments.krizbeatzmusicacademy.com/#/standalone-amm">
            <button className="button sa-enrol-btn mt-4">Enrol</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default StandaloneCourses;
