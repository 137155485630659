import React from 'react'
import congratImg from '../../assets/video/welcome.gif'

import './Redirect.css'

const Redirect = () => {
  return (
    <>
      <div className='redirect-container'>
        <img src={congratImg} alt='Congratulations to new Students' />
        <h2 className='mt-4'>Congratulations!</h2>
        <p className='mt-4'>
          You are now a student of the <strong>Krizbeatz Music Academy.</strong> This is a great
          step to becoming one of the baddest producer. Rest assured you will be
          getting a lot of things from the academy. Also watch out for our
          emails as we will be reaching out on how to fully start the class.
        </p>
        <a href='https://krizbeatzmusicacademy.com'><button className="button primary-btn mt-5">Go back home</button></a>
      </div>
    </>
  )
}

export default Redirect
