import React from 'react'
import Footer from '../../components/footer/Footer'
import GetStarted from '../../components/get_started/GetStarted'
import Modal from '../../components/modal/Modal'
import Nav from '../../components/navigation/Nav'
import PricingHero from '../../components/pricing_hero/PricingHero'
import PricingSection from '../../components/pricing_page/PricingSection'

const PricingPage = () => {
  return (
    <>
      <Nav />
      <PricingHero />
      <PricingSection />
      <GetStarted />
      <Footer />
      <Modal />
    </>
  )
}

export default PricingPage
