import React from 'react'

import './HowToEnrol.css'

const HowToEnrol = () => {
  const enrolData = [
    {
      id: 1.0,
      index: 1,
      title: 'Enrol in Minutes',
      description: 'Click on enrol button and start your registration journey.'
    },
    {
      id: 1.1,
      index: 2,
      title: 'Choose your level',
      description:
        'We have three level categories. Choose which best describes you.'
    },

    {
      id: 1.2,
      index: 3,
      title: 'Submit your personal Details',
      description: 'Fill the form to submit your personal details.'
    },
    {
      id: 1.3,
      index: 4,
      title: 'Make Payment',
      description:
        'Payment validates registration. We have various option of payment.'
    }
  ]

  return (
    <>
      <div className='custom-container hte-container'>
        <h1 className='hte-text'>
          How to Enrol and start learning from Krizbeats and other Industry
          experts.
        </h1>
        <p className='hte-small-text'>
          Making music has never been this seamless
        </p>
        <div className='how-to-steps'>
          {enrolData.map(({ id, index, title, description }) => {
            return (
              <div className='first' key={id}>
                <div className='num'>
                  <p>{index}</p>
                </div>
                <h5>{title}</h5>
                <p>{description}</p>
              </div>
            )
          })}
        </div>
        <button
          className='button ln-mr-btn'
          data-bs-toggle='modal'
          data-bs-target='#enrolModal'
        >
          Enrol now <i className='fa-solid fa-arrow-right-long ms-3'></i>
        </button>
      </div>
    </>
  )
}

export default HowToEnrol
