import React from "react";

import "./Modal.css";

const Modal = () => {
  return (
    <div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="enrolModal"
        tabIndex="-1"
        aria-labelledby="enrolModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content pt-3 pb-5 px-3">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="enrolModalLabel">
                Choose your level to Enrol
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Beginner */}
              <div className="beginner-level d-flex align-items-center justify-content-between">
                <p className="fs-5">Beginner</p>
                <a href="https://payments.krizbeatzmusicacademy.com/#/beginner-full">
                  <p className="select-selected fs-5">Enrol</p>
                </a>
              </div>

              <div className="divider mt-3 modal-divide"></div>

              {/* Intermediate */}
              <div className="beginner-level d-flex align-items-center justify-content-between mt-3">
                <p className="fs-5">Intermediate</p>
                <a href="https://payments.krizbeatzmusicacademy.com/#/intermediate-full">
                  <p className="select-selected fs-5">Enrol</p>
                </a>
              </div>

              <div className="divider mt-3 modal-divide"></div>

              {/* Advanced */}
              <div className="beginner-level d-flex align-items-center justify-content-between mt-3">
                <p className="fs-5">Advanced</p>
                <a href="https://payments.krizbeatzmusicacademy.com/#/advanced-full">
                  <p className="select-selected fs-5">Enrol</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
