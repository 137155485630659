import React from 'react'

import './Policies.css'

const Policies = () => {
  return (
    <>
      <div className='custom-container'>
        <p className='updated'>Last Updated: March 1st, 2023 </p>
        <div className='policy-container'>
          <ol>
            <li className='bold p-title'>Introduction:</li>
            <p className='inner-lists'>
              The Krizbeatz Music Academy (hereinafter referred to as "the
              Academy") is committed to protecting the privacy of its students,
              users, and website visitors. This Privacy Policy explains the
              Academy's practices regarding the collection, use, and disclosure
              of Personal Information through the Academy's website and related
              services.
            </p>
            <li className='bold p-title'>Personal Information Collection:</li>
            <p className='inner-lists'>
              The Academy collects Personal Information from its students,
              users, and website visitors when they register for the Academy's
              services, participate in online activities, or communicate with
              the Academy through the website. Personal Information may include,
              but is not limited to, the Student's name, email address, postal
              address, phone number, and payment information.
            </p>
            <li className='bold p-title'>Use of Personal Information:</li>
            <p className='inner-lists'>
              The Academy may use the Personal Information collected from its
              students, users, and website visitors for a variety of purposes,
              including but not limited to:
              <ul>
                <li>Providing the Academy's services and support</li>
                <li>Processing payments and transactions</li>
                <li>Sending newsletters and promotional materials</li>
                <li>Improving the Academy's website and services</li>
              </ul>
            </p>
            <li className='bold p-title'>
              Disclosure of Personal Information:
            </li>
            <p className='inner-lists'>
              The Academy does not sell, rent, or share the Personal Information
              of its students, users, or website visitors with third parties,
              except as required by law or as necessary to provide the Academy's
              services. The Academy may share Personal Information with
              third-party service providers for the purpose of processing
              payments or providing support services.
            </p>
            <li className='bold p-title'>Data Security:</li>
            <p className='inner-lists'>
              The Academy takes reasonable measures to protect the security of
              the Personal Information collected through its website and
              services. However, the Academy cannot guarantee the absolute
              security of Personal Information, and the Student acknowledges
              that any information transmitted over the internet is inherently
              at risk of unauthorized access or theft.
            </p>
            <li className='bold p-title'>Changes to this Privacy Policy:</li>
            <p className='inner-lists'>
              The Academy may update this Privacy Policy from time to time to
              reflect changes in its practices or to comply with legal
              requirements. The Academy will post any changes to the Privacy
              Policy on its website, and the Student is encouraged to review the
              Privacy Policy regularly to stay informed of the Academy's
              practices regarding the collection, use, and disclosure of
              Personal Information.
            </p>
            <li className='bold p-title'>Contact Information:</li>
            <p className='inner-lists'>
              If the Student has any questions or concerns about this Privacy
              Policy or the Academy's practices regarding the collection, use,
              or disclosure of Personal Information, the Student may contact the
              Academy at{' '}
              <a href='mailto:contact@krizbeatz.org'>info@krizbeatz.org</a>
            </p>
          </ol>
        </div>
      </div>
    </>
  )
}

export default Policies
