import React from 'react'
import './Assurance..css'

const Assurance = () => {

    //TODO: Map through contents
    // const assuredContainer = [
    //     {

    //     }
    // ]

  return (
    <>
      <div className='custom-container assurance-container'>
        <div className='top-text'>
          <span>You have nothing to worry about.</span> <br />
          <span>Krizbeatz got you!</span>
        </div>
        <div className='assured-containers mt-5'>
          <div className='assured-container'>
            <div className='assurance-icon'>
              <i className='fa-solid fa-hand-holding-heart'></i>
            </div>
            <div className='assurance-text'>
              <p>Tailored to meet Individual needs</p>
            </div>
          </div>
          <div className='assured-container'>
            <div className='assurance-icon'>
            <i className="fa-solid fa-brain"></i>
            </div>
            <div className='assurance-text'>
              <p>Enhance your production skills</p>
            </div>
          </div>
          <div className='assured-container'>
            <div className='assurance-icon'>
            <i className="fa-solid fa-people-arrows"></i>
            </div>
            <div className='assurance-text'>
              <p>One on one live feed</p>
            </div>
          </div>
          <div className='assured-container'>
            <div className='assurance-icon'>
            <i className="fa-solid fa-screwdriver-wrench"></i>
            </div>
            <div className='assurance-text'>
              <p>Access to tools to make professional sounds</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Assurance
