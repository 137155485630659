import React from 'react'
import Footer from '../../components/footer/Footer'
import Modal from '../../components/modal/Modal'
import Nav from '../../components/navigation/Nav'
import TermsHero from '../../components/terms_hero/TermsHero'
import TermsOfUse from '../../components/terms_of_use/TermsOfUse'

const Terms = () => {
  return (
    <>
      <Nav />
      <TermsHero />
      <TermsOfUse />
      <Footer />
      <Modal />
    </>
  )
}

export default Terms
