import React from "react";
import Faq from "react-faq-component";
import { Link } from "react-router-dom";

import "./CourseOutline.css";

const CourseOutline = () => {
  const courseOutline = {
    rows: [
      {
        title: <h6>Module 1: Introduction to Music Production</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Introduction to the music production
              process and the role of the music producer
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Setting up a digital audio workstation
              (DAW) and basic navigation
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Introduction to music theory and how it
              relates to music production
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Recording basics: microphone techniques
              and setting up a home studio
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 2: Sound Design and Synthesis</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Introduction to sound design and
              synthesis concepts
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Basic synthesizer programming and sound
              design techniques
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Advanced synthesizer programming and
              sound design techniques
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Sampling and sample-based production
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 3: Recording and Editing</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Recording techniques for different
              instruments and vocal recording
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Basic audio editing techniques
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Advanced audio editing techniques and
              using editing to create unique sounds
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Introduction to mixing and balancing
              levels
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 4: Music Production Techniques</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Music arrangement and composition
              techniques
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Mixing and mastering techniques
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Using effects and processors to enhance
              the mix
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Putting it all together: creating a
              final mix
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 5: Music Business and Marketing</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Copyright and distribution basics
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Music promotion and social media
              marketing
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Networking and building a professional
              network
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Building a brand and creating a
              sustainable business model
            </li>
          </ul>
        ),
      },
    ],
  };

  const styles = {
    bgColor: "transparent",
    titleTextColor: "black",
    rowTitleColor: "black",
    rowContentColor: "grey",
    arrowColor: "grey",
    marginTop: "3rem",
  };

  const config = {
    animate: true,
    tabFocus: true,
  };

  return (
    <>
      <div className="custom-container co-container">
        <h1>Course Outline</h1>
        <p className="mb-5">
          These are list of all courses you will be taught in class by
          professionals in the industry.
        </p>
        <Faq data={courseOutline} styles={styles} config={config} />
    
      <Link to="/courses">
        <button
          className="button ln-mr-btn"
        >
          Learn more <i className="fa-solid fa-arrow-right-long ms-3"></i>
        </button>

      </Link>
      </div>
    </>
  );
};

export default CourseOutline;
