import React from 'react'
import errorGif from '../../assets/video/404.gif'
import Nav from '../navigation/Nav'
import './NotFound.css'

const NotFound = () => {
  return (
    <>
      <Nav />
      <div className='custom-container error-container'>
        <img src={errorGif} alt='Error to show not found page' />
        <p>
          You might have entered the wrong url. Click this{' '}
          <a href='https://krizbeatzmusicacademy.com'>link</a> to go back to the
          main page or use the navbar.
        </p>
      </div>
    </>
  )
}

export default NotFound
