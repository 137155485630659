import React from "react";
import Faq from "react-faq-component";

import "./CourseHero.css";

const CourseHero = () => {
  // Beginner
  const courseOutlineBeginner = {
    rows: [
      {
        title: <h6>Module 1: Beat Making:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Introduction to Beat Making.
              <ul>
                <li>What is Beat Making?</li>
                <li>Overview of Beat Making software and hardware.</li>
                <li>Understanding rhythm and timing.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Drum Programming
              <ul>
                <li>Introduction to drum programming.</li>
                <li>Basics of sequencing drum patterns.</li>
                <li>Layering drum sounds for depth.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Melodic Elements
              <ul>
                <li>Adding melodic elements to your beat.</li>
                <li>Basics of choosing instruments and samples.</li>
                <li>Creating catchy melodies and chord progressions.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Arrangement
              <ul>
                <li>Structuring your beat for coherence.</li>
                <li>Introduction to verse, chorus, and bridge sections.</li>
                <li>Adding variation to keep the beat interesting.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 5:</strong> Mixing Basics for Beats
              <ul>
                <li>Introduction to mixing for beat making.</li>
                <li>Understanding levels, panning, and EQ.</li>
                <li>
                  Basic techniques for enhancing the clarity and impact of your
                  beat.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 2: Basic Mixing:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Introduction to Mixing
              <ul>
                <li>What is mixing and why is it important?</li>
                <li>Overview of the mixing process.</li>
                <li>Understanding the mixing environment.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Setting Up Your Session
              <ul>
                <li>Organizing tracks in your DAW.</li>
                <li>Setting levels and basic routing.</li>
                <li>Understanding signal flow.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Balancing Tracks
              <ul>
                <li>Adjusting levels to achieve a balanced mix.</li>
                <li>Using panning to create space and separation.</li>
                <li>Understanding the concept of headroom.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> EQ Fundamentals
              <ul>
                <li>Introduction to equalization (EQ).</li>
                <li>Understanding frequency bands and controls.</li>
                <li>Using EQ to shape the tone of individual tracks.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 5:</strong> Dynamics Processing
              <ul>
                <li>Introduction to compression and its uses.</li>
                <li>
                  Basics of setting threshold, ratio, attack, and release.
                </li>
                <li>
                  Using compression to control dynamics and add punch to your
                  mix.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 3: Sound Design:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Introduction to Sound Design
              <ul>
                <li>What is Sound Design?</li>
                <li>Overview of synthesizers and samplers.</li>
                <li>
                  Understanding sound parameters like pitch, timbre, and
                  amplitude.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Synthesizer Basics
              <ul>
                <li>Introduction to subtractive synthesis.</li>
                <li>Understanding oscillators, filters, and envelopes.</li>
                <li>Creating basic synth patches from scratch.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Sample Manipulation
              <ul>
                <li>Working with samples in your DAW.</li>
                <li>Basics of chopping, slicing, and rearranging samples.</li>
                <li>
                  Using effects to manipulate sample textures and
                  characteristics.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Creating Atmospheres and Textures
              <ul>
                <li>Layering sounds for depth and complexity.</li>
                <li>Introduction to effects like reverb and delay.</li>
                <li>Techniques for creating ambient soundscapes.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 5:</strong> Experimentation and Creative Techniques
              <ul>
                <li>Encouraging experimentation and exploration.</li>
                <li>Using modulation effects for movement and interest.</li>
                <li>
                  Incorporating unconventional sound sources for unique
                  textures.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
    ],
  };

  // Intermediate
  const courseOutlineIntermediate = {
    rows: [
      {
        title: <h6>Module 1: Setting Yourself Up for Success:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Defining Your Goals as a Music Producer
              <ul>
                <li>Understanding your long-term and short-term goals.</li>
                <li>
                  Setting SMART (Specific, Measurable, Achievable, Relevant,
                  Time-bound) goals for your music production journey.
                </li>
                <li>Creating a roadmap to achieve your goals.</li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Understanding Sound and Acoustics
              <ul>
                <li>Advanced exploration of sound waves and frequencies.</li>
                <li>
                  Delving deeper into the science behind acoustics and how it
                  affects your music production.
                </li>
                <li>
                  Understanding psychoacoustics and its implications for mixing
                  and mastering.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Setting Up Your Environment for Optimal
              Production
              <ul>
                <li>Advanced techniques for optimizing your studio space.</li>
                <li>
                  Implementing acoustic treatment to minimize room reflections
                  and improve sound accuracy.
                </li>
                <li>
                  Exploring DIY acoustic treatment options for cost-effective
                  solutions.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 2: Mixing & Mastering:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Mixing Techniques
              <ul>
                <li>
                  Creating a personalized mixing template tailored to your
                  workflow and preferences.
                </li>
                <li>
                  Utilizing advanced mixing tools and plugins for precise
                  control over your mix.
                </li>
                <li>
                  Utilizing advanced mixing tools and plugins for precise
                  control over your mix.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Mastering from Start to Finish
              <ul>
                <li>
                  Understanding the mastering process and its role in music
                  production.
                </li>
                <li>
                  Advanced mastering techniques for achieving
                  professional-sounding results.
                </li>
                <li>
                  Using reference tracks and mastering chains to enhance the
                  quality of your final mix.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 3: Building Your Career in Music:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Making Your Own Samples
              <ul>
                <li>
                  Advanced techniques for sound design and sample creation.
                </li>
                <li>
                  Incorporating field recordings, synthesis, and processing to
                  craft unique and original sounds.
                </li>
                <li>
                  Building a library of custom samples to enhance your
                  production workflow.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Getting Placement and Exposure
              <ul>
                <li>
                  Strategies for getting your music placed in films, TV shows,
                  commercials, and video games.
                </li>
                <li>
                  Networking tips for connecting with industry professionals and
                  securing placements.
                </li>
                <li>
                  Utilizing online platforms and social media to gain exposure
                  for your music.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Building Your Career in the Music
              Industry
              <ul>
                <li>
                  Understanding the music industry landscape and identifying
                  opportunities for growth.
                </li>
                <li>
                  Developing a personal brand and marketing strategy to stand
                  out as a music producer.
                </li>
                <li>
                  Building relationships with collaborators, labels, and
                  industry influencers to advance your career.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
    ],
  };

  // Advanced
  const courseOutlineAdvanced = {
    rows: [
      {
        title: <h6>Module 1: Setting Yourself Up for Success:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Setting Clear Objectives as a Music
              Producer
              <ul>
                <li>
                  Advanced goal-setting techniques tailored to your specific
                  career aspirations.
                </li>
                <li>
                  Creating a detailed action plan to achieve your objectives.
                </li>
                <li>
                  Implementing strategies for maintaining motivation and
                  accountability.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Advanced Understanding of Sound and
              Acoustics
              <ul>
                <li>
                  Advanced exploration of waveforms, spectral analysis, and
                  sound propagation.
                </li>
                <li>
                  Applying advanced acoustical principles to optimize your
                  studio environment for precision and accuracy.
                </li>
                <li>
                  Utilizing cutting-edge technologies and tools for measuring
                  and analyzing sound.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Advanced Acoustic Treatment Techniques
              <ul>
                <li>
                  Advanced techniques for designing and implementing acoustic
                  treatments to achieve optimal sound quality.
                </li>
                <li>
                  Customizing acoustic treatments for specific frequency ranges
                  and sonic characteristics.
                </li>
                <li>
                  Incorporating advanced materials and construction methods for
                  superior sound isolation and absorption.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 2: Mixing & Mastering:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Mastering Techniques for Different
              Media
              <ul>
                <li>
                  Advanced mastering techniques tailored to specific delivery
                  formats, such as streaming, vinyl, and broadcast.
                </li>
                <li>
                  Understanding the nuances of mastering for different playback
                  systems and environments.
                </li>
                <li>
                  Incorporating metadata and other technical considerations for
                  optimal compatibility and quality.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Creative FX and Sound Design Mastery
              <ul>
                <li>
                  Exploring advanced creative effects and sound design
                  techniques to push the boundaries of your productions.
                </li>
                <li>
                  Harnessing the power of modular synthesis, granular synthesis,
                  and experimental processing.
                </li>
                <li>
                  Pushing the limits of conventional sound manipulation to
                  create truly innovative and unique sonic experiences.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 3: Building Your Career in Music:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Strategies for Copyrights and
              Publishing
              <ul>
                <li>
                  Understanding the intricacies of copyright law and
                  intellectual property rights in the music industry.
                </li>
                <li>
                  Implementing advanced strategies for protecting your creative
                  works and maximizing revenue streams through publishing.
                </li>
                <li>
                  Navigating the complexities of licensing agreements,
                  royalties, and publishing deals.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Advanced Business Planning and
              Professional Development
              <ul>
                <li>
                  Developing a comprehensive business plan and long-term career
                  strategy for sustainable success.
                </li>
                <li>
                  Leveraging advanced marketing, branding, and promotion tactics
                  to expand your reach and influence.
                </li>
                <li>
                  Investing in continuous professional development and personal
                  growth to stay ahead in a competitive industry.
                </li>
              </ul>
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Mentorship and Live Feedback
              <ul>
                <li>
                  Accessing personalized one-on-one mentorship and guidance from
                  industry professionals.
                </li>
                <li>
                  Receiving live feedback and constructive criticism on your
                  work to accelerate your growth and development.
                </li>
                <li>
                  Engaging in a structured mentorship program for ongoing
                  support and mentorship post-school.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 4: Music Production (Ableton):</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Music Production Techniques in
              Ableton Live
              <ul>
                <li>
                  Mastering the intricacies of Ableton Live's advanced features
                  and workflow optimizations.
                </li>
                <li>
                  Exploring advanced production techniques such as complex MIDI
                  routing, custom device creation, and Max for Live integration.
                </li>
                <li>
                  Harnessing the power of Ableton Live's native instruments,
                  effects, and third-party plugins for cutting-edge sound design
                  and production.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 5: Recording & Layering:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Recording and Layering
              Techniques
              <ul>
                <li>
                  Mastering advanced recording techniques for capturing pristine
                  audio in various environments and scenarios.
                </li>
                <li>
                  Exploring advanced layering techniques to add depth, texture,
                  and dimension to your recordings.
                </li>
                <li>
                  Incorporating advanced microphone placement, signal routing,
                  and processing techniques for professional-quality recordings.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 6: Afrobeats Sampling:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Afrobeats Sampling and
              Production
              <ul>
                <li>
                  Delving deeper into the rhythmic and melodic intricacies of
                  Afrobeats music production.
                </li>
                <li>
                  Advanced sampling techniques for incorporating authentic
                  Afrobeats elements into your productions.
                </li>
                <li>
                  Exploring advanced rhythmic programming, percussion layering,
                  and groove manipulation techniques characteristic of Afrobeats
                  music.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 7: Signing Up with a Sync Licensing Company:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Strategies for Sync Licensing
              and Music Placement
              <ul>
                <li>
                  Navigating the intricacies of sync licensing agreements,
                  negotiations, and royalties.
                </li>
                <li>
                  Developing advanced pitching strategies to secure placements
                  in film, TV, advertising, and gaming.
                </li>
                <li>
                  Building relationships with sync licensing companies, music
                  supervisors, and industry decision-makers to maximize
                  opportunities for music placement.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 8: Making Your First Major $$$:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Strategies for Monetizing Your
              Music
              <ul>
                <li>
                  Exploring advanced revenue streams and income-generating
                  opportunities in the music industry.
                </li>
                <li>
                  Leveraging advanced marketing, branding, and promotional
                  tactics to increase visibility and monetization potential.
                </li>
                <li>
                  Developing strategic partnerships, sponsorships, and
                  endorsements to diversify revenue streams and maximize
                  earnings.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 9: Making Remixes Live Demo & Making DJ Mashups:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Remixing and Mashup Techniques
              <ul>
                <li>
                  Mastering advanced remixing techniques for transforming
                  existing tracks into innovative reinterpretations.
                </li>
                <li>
                  Exploring advanced DJ mashup techniques for creating seamless
                  blends and mashups that captivate audiences.
                </li>
                <li>
                  Incorporating live performance elements, improvisation, and
                  creative sampling to elevate your remixes and mashups to the
                  next level.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 10: A&R (Artist and Repertoire):</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced A&R Strategies
              <ul>
                <li>
                  Mastering the role of A&R in identifying and developing talent
                  in the music industry.
                </li>
                <li>
                  Exploring advanced scouting techniques for discovering
                  emerging artists and bands.
                </li>
                <li>
                  Developing advanced artist development strategies to nurture
                  talent and guide artists towards success.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 11: Mastering in Action:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Mastering Techniques in
              Practice
              <ul>
                <li>
                  Applying advanced mastering techniques to real-world scenarios
                  and projects.
                </li>
                <li>
                  Hands-on mastering sessions to refine and polish mixes for
                  commercial release.
                </li>
                <li>
                  Utilizing advanced mastering tools and processors to achieve
                  professional-grade results.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 12: Planning for Business:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Business Planning and
              Entrepreneurship
              <ul>
                <li>
                  Developing advanced business plans and strategies for
                  launching and scaling a successful music business.
                </li>
                <li>
                  Exploring advanced financing options, investment strategies,
                  and fundraising techniques for music startups
                </li>
                <li>
                  Navigating the legal, financial, and regulatory challenges of
                  running a music business in today's industry landscape.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 13: Professional Development:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Professional Development and
              Networking
              <ul>
                <li>
                  Leveraging advanced networking strategies to build meaningful
                  relationships and connections within the music industry.
                </li>
                <li>
                  Engaging in advanced professional development opportunities
                  such as conferences, workshops, and industry events.
                </li>
                <li>
                  Developing advanced negotiation, communication, and leadership
                  skills to thrive in a competitive industry.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 14: One-on-One Live Feedback and Mentorship:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Mentorship and Career Guidance
              <ul>
                <li>
                  Accessing personalized one-on-one mentorship and career
                  guidance from industry experts.
                </li>
                <li>
                  Receiving live feedback and critique on advanced projects and
                  portfolio pieces.
                </li>
                <li>
                  Engaging in advanced mentorship programs for ongoing support
                  and guidance in navigating the complexities of the music
                  industry.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 15: Three Months Mentorship Post School:</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Advanced Post-School Mentorship Program
              <ul>
                <li>
                  Participating in a structured mentorship program for three
                  months following graduation.
                </li>
                <li>
                  Receiving personalized guidance, feedback, and support from
                  experienced industry mentors.
                </li>
                <li>
                  Leveraging the mentorship program to accelerate career growth,
                  develop new skills, and achieve professional success in the
                  music industry.
                </li>
              </ul>
            </li>
          </ul>
        ),
      },
    ],
  };

  //For Mixing And Mastering Course (Afrobeats)
  const afrobeatMixingAndMastering = {
    rows: [
      {
        title: <h6>Module 1: Preparation and Introduction to Mixing</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Preparing a mix session: Setting up
              your DAW, organizing tracks, and understanding session workflow.
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> What is mixing: Understanding the role
              of mixing in music production and its importance.
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Monitoring: Choosing the right
              monitoring setup and understanding its impact on mixing.
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Mixing point and monitoring position:
              Positioning yourself and your monitors for optimal mixing.
            </li>
            <li className="p-1">
              <strong>Lesson 5:</strong> First reflection: Reflecting on your
              mixing goals and expectations.
            </li>
            <li className="p-1">
              <strong>Lesson 6:</strong> Reference: Utilizing reference tracks
              to guide your mixing decisions.
            </li>
            <li className="p-1">
              <strong>Lesson 7:</strong> Bit depth and sample rate:
              Understanding the technical aspects of digital audio.
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 2: Introduction to Frequency and Equalization</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Introduction to Frequency and
              Resonance: Understanding the frequency spectrum and resonance in
              audio.
            </li>
            <li className="p-1">
              <strong>Lesson 1:</strong> Introduction to Frequency and
              Resonance: Understanding the frequency spectrum and resonance in
              audio.
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Introduction to EQ: Exploring the
              basics of equalization and its role in shaping the sound.
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Types of EQ: Understanding different
              types of EQ filters and their applications.
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Common EQ vs Linear EQ: Comparing
              traditional EQs to linear phase EQs.
            </li>
            <li className="p-1">
              <strong>Lesson 5:</strong> Corrective EQ: Using EQ to correct
              frequency imbalances in audio.
            </li>
            <li className="p-1">
              <strong>Lesson 6:</strong> ADDICTIVE EQ and Analog Emulations:
              Exploring analog-style EQs for coloration.
            </li>
            <li className="p-1">
              <strong>Lesson 7:</strong> MID-SIDE EQ and Advanced EQ Techniques:
              Techniques for precise EQ adjustments and stereo image control.
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 3: Compression Fundamentals</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Introduction to Compression:
              Understanding the basics of dynamic range compression.
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Threshold & Ratio: Setting compression
              thresholds and ratios for desired effects.
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Peak and RMS: Understanding peak and
              RMS measurement in compression.
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Attack & Release: Controlling the
              dynamics of audio with attack and release settings.
            </li>
            <li className="p-1">
              <strong>Lesson 5:</strong> Knee and Parallel Compression:
              Exploring knee settings and utilizing parallel compression.
            </li>
            <li className="p-1">
              <strong>Lesson 6:</strong> Sidechaining: Using sidechain
              compression for dynamic control.
            </li>
            <li className="p-1">
              <strong>Lesson 7:</strong> Vocal Compression: Techniques for
              compressing vocals effectively.
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 4: Saturation, Dynamic Processing, and Effects</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Introduction to Saturation:
              Understanding saturation and its effects on audio.
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Types of Saturation: Exploring
              different types of saturation processes.
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Transient Processing: Techniques for
              shaping transients in audio.
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Limiter, De-esser, Gate, and Expander:
              Understanding and utilizing dynamic processing tools.
            </li>
            <li className="p-1">
              <strong>Lesson 5:</strong> Multiband Compression: Using multiband
              compression for frequency-specific dynamic control.
            </li>
          </ul>
        ),
      },
      {
        title: (
          <h6>
            Module 5: Mono Compatibility, Ambience, Modulation, and Stereo
            Imaging
          </h6>
        ),
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Mono Compatibility: Ensuring your mix
              translates well to mono playback systems.
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Delays and Reverb: Exploring delay and
              reverb effects and their parameters.
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Introduction to Modulators:
              Understanding modulation effects such as chorus and flanging.
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> (CREATIVE FX Advance)
            </li>
            <li className="p-1">
              <strong>Lesson 5:</strong> Panning and Stereo Imaging: Techniques
              for creating width and depth in your mix.
            </li>
          </ul>
        ),
      },
      {
        title: <h6>Module 6: Balancing, Mixing, and Conclusion</h6>,
        content: (
          <ul>
            <li className="p-1">
              <strong>Lesson 1:</strong> Headroom and Tonal Balance: Managing
              headroom and achieving tonal balance in your mix.
            </li>
            <li className="p-1">
              <strong>Lesson 2:</strong> Mixing Specific Elements of Afrobeats:
              Techniques for mixing drums, guitars, synths, and vocals in
              Afrobeats.
            </li>
            <li className="p-1">
              <strong>Lesson 3:</strong> Mixing Kick
            </li>
            <li className="p-1">
              <strong>Lesson 4:</strong> Mixing a Log Drum (amapiano)
            </li>
            <li className="p-1">
              <strong>Lesson 5:</strong> Exporting Mixes for Mastering:
              Preparing your mixes for the mastering process.
            </li>
            <li className="p-1">
              <strong>Lesson 6:</strong> Mastering to Test: Testing your
              mastered tracks across different playback systems.
            </li>
            <li className="p-1">
              <strong>Lesson 7:</strong> Conclusion of Mixing: Reflecting on the
              mixing process and setting future goals.
            </li>
          </ul>
        ),
      },
    ],
  };

  const styles = {
    bgColor: "transparent",
    titleTextColor: "black",
    rowTitleColor: "black",
    rowContentColor: "grey",
    arrowColor: "grey",
    marginTop: "3rem",
  };

  const config = {
    animate: true,
    tabFocus: true,
  };
  return (
    <>
      <div className="custom-container courses-container">
        <h1>Course Outline</h1>
        <p>These are courses that will be taught during the class</p>
      </div>

      {/* Beginner */}
      <div className="custom-container beginner">
        <h1>Beginner</h1>
        <p className="mb-5">
          These are list of all courses for beginners. Kindly go through them
          for your perusal.
        </p>
        <Faq data={courseOutlineBeginner} styles={styles} config={config} />
        <p className="pt-4">
          Each lesson will include practical exercises and examples to help
          reinforce the concepts covered, catering to beginners in Beat Making,
          Basic Mixing, and Sound Design.
        </p>
      </div>

      {/* Intermediate */}
      <div className="custom-container intermediate">
        <h1>Intermediate</h1>
        <p className="mb-5">
          These are list of all courses for intermediates. Kindly go through
          them for your perusal.
        </p>
        <Faq data={courseOutlineIntermediate} styles={styles} config={config} />
        <p className="pt-4">
          Each lesson will provide in-depth knowledge and practical exercises to
          help intermediate students further develop their skills and advance
          their careers in music production.
        </p>
      </div>

      {/* Advanced */}
      <div className="custom-container advanced">
        <h1>Advanced</h1>
        <p className="mb-5">
          These are list of all courses for advanced. Kindly go through them for
          your perusal.
        </p>
        <Faq data={courseOutlineAdvanced} styles={styles} config={config} />
        <p className="pt-4">
          Each advanced lesson will provide students with the knowledge, skills,
          and resources needed to excel in their music careers, navigate the
          complexities of the industry, and achieve their goals at the highest
          level of proficiency.
        </p>
      </div>

      {/* Afrobeat Mixing and Mastering */}
      <div className="custom-container advanced">
        <h1>Mixing And Mastering Course (Afrobeats)</h1>
        <p className="mb-5">
          These are list of all courses for Afrobeats Mixing and mastering.
          Kindly go through them for your perusal.
        </p>
        <Faq
          data={afrobeatMixingAndMastering}
          styles={styles}
          config={config}
        />
        <p className="pt-4">
          Each week will include practical exercises and assignments to
          reinforce the concepts learned, as well as access to additional
          resources and support from instructors.
        </p>

        <button
          className="button primary-btn mt-5 co-enrol-btn"
          data-bs-toggle="modal"
          data-bs-target="#enrolModal"
        >
          Enrol now <i className="fa-solid fa-arrow-right-long ms-3"></i>
        </button>
      </div>
    </>
  );
};

export default CourseHero;
