import nice from "../../assets/images/client-img/9ice.png"; //9ice.png
import davido from "../../assets/images/client-img/davido.png";
import dp from "../../assets/images/client-img/dp.png";
import yemiAlade from "../../assets/images/client-img/ya.png";
import fave from "../../assets/images/client-img/fave.png";
import tekno from "../../assets/images/client-img/tekno.png";
import skales from "../../assets/images/client-img/skales.png";
import omahLay from "../../assets/images/client-img/omah-lay.png";
import bellaShmurda from "../../assets/images/client-img/bella.png";
import harmonizer from "../../assets/images/client-img/harmonize.png";
import lilKesh from "../../assets/images/client-img/lilkesh.png";
import ruggedMan from "../../assets/images/client-img/ruggedman.png";
import seyiVibes from "../../assets/images/client-img/seyivibes.png";
import reminisce from "../../assets/images/client-img/remi.png";
import lade from "../../assets/images/client-img/lade.png";
import raybekah from "../../assets/images/client-img/raybekah.png";
import victony from "../../assets/images/client-img/victony.png";
import terri from "../../assets/images/client-img/terri.png";
import falz from "../../assets/images/client-img/falz.png";
import rayvanny from "../../assets/images/client-img/rayvanny.png";
import teni from "../../assets/images/client-img/teni.png";
import oladips from "../../assets/images/client-img/oladips.png";
import chinkoEkun from "../../assets/images/client-img/chinko.png";
import fuseODG from "../../assets/images/client-img/fuse.png";
import yungL from "../../assets/images/client-img/yungl.png";
import seyiShay from "../../assets/images/client-img/seyishay.png";
import phyno from "../../assets/images/client-img/phyno.png";
import timaya from "../../assets/images/client-img/timaya.png";
import ceeboi from "../../assets/images/client-img/ceeboi.png";
import reekadoBanks from "../../assets/images/client-img/reekado.png";
import mrEazi from "../../assets/images/client-img/mreazi.png";
import vanessaMdee from "../../assets/images/client-img/vanessa-mdee.png";
import mayorkun from "../../assets/images/client-img/mayorkun.png";
import google from "../../assets/images/client-img/google.png";
import uba from "../../assets/images/client-img/uba.png";
import gpe from "../../assets/images/client-img/gpe.png";
import youtube from "../../assets/images/client-img/youtube.png";
import fentyBeauty from "../../assets/images/client-img/fenty.png";

const clientImages = [
  fentyBeauty,
  google,
  uba,
  gpe,
  youtube,
  davido,
  yemiAlade,
  lade,
  raybekah,
  dp,
  bellaShmurda,
  phyno,
  fave,
  teni,
  victony,
  tekno,
  seyiVibes,
  omahLay,
  harmonizer,
  lilKesh,
  ruggedMan,
  reminisce,
  terri,
  rayvanny,
  falz,
  oladips,
  chinkoEkun,
  fuseODG,
  skales,
  yungL,
  seyiShay,
  timaya,
  ceeboi,
  reekadoBanks,
  mrEazi,
  vanessaMdee,
  mayorkun,
  nice,
];

const clients = [
  {
    id: 1,
    client: clientImages[0],
  },
  {
    id: 2,
    client: clientImages[1],
  },
  {
    id: 3,
    client: clientImages[2],
  },
  {
    id: 4,
    client: clientImages[3],
  },
  {
    id: 5,
    client: clientImages[4],
  },
  {
    id: 6,
    client: clientImages[5],
  },
  {
    id: 7,
    client: clientImages[6],
  },
  {
    id: 8,
    client: clientImages[7],
  },
  {
    id: 9,
    client: clientImages[8],
  },
  {
    id: 10,
    client: clientImages[9],
  },
  {
    id: 11,
    client: clientImages[10],
  },
  {
    id: 12,
    client: clientImages[11],
  },
  {
    id: 13,
    client: clientImages[12],
  },
  {
    id: 14,
    client: clientImages[13],
  },
  {
    id: 15,
    client: clientImages[14],
  },
  {
    id: 16,
    client: clientImages[15],
  },
  {
    id: 17,
    client: clientImages[16],
  },
  {
    id: 18,
    client: clientImages[17],
  },
  {
    id: 19,
    client: clientImages[18],
  },
  {
    id: 20,
    client: clientImages[19],
  },
  {
    id: 21,
    client: clientImages[20],
  },
  {
    id: 22,
    client: clientImages[21],
  },
  {
    id: 23,
    client: clientImages[22],
  },
  {
    id: 24,
    client: clientImages[23],
  },
  {
    id: 25,
    client: clientImages[24],
  },
  {
    id: 26,
    client: clientImages[25],
  },
  {
    id: 27,
    client: clientImages[26],
  },
  {
    id: 28,
    client: clientImages[27],
  },
  {
    id: 29,
    client: clientImages[28],
  },
  {
    id: 30,
    client: clientImages[29],
  },
  {
    id: 31,
    client: clientImages[30],
  },
  {
    id: 32,
    client: clientImages[31],
  },
  {
    id: 33,
    client: clientImages[32],
  },
  {
    id: 34,
    client: clientImages[33],
  },
  {
    id: 35,
    client: clientImages[34],
  },
  {
    id: 36,
    client: clientImages[35],
  },
  {
    id: 37,
    client: clientImages[36],
  },
  {
    id: 38,
    client: clientImages[37],
  },
];

export default clients;
