import React from 'react'
import AboutUs from '../../components//about_us/AboutUs'
import Footer from '../../components/footer/Footer'
import Modal from '../../components/modal/Modal'
import Nav from '../../components/navigation/Nav'

const About = () => {

  return (
    <>
      <div>
        <Nav />
        <AboutUs />
        <Footer />
        <Modal />
      </div>
    </>
  )
}

export default About
