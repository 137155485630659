import React from 'react'

import './GetStarted.css'

const GetStarted = () => {
  return (
    <>
      <div className='custom-container'>
        <div className='get-started'>
          <h1 className='py-3'>Ready to get Started?</h1>
          <p>
            Click on enrol button to book your class now and start learning.
            Every student has the opportunity to join a one on one feed with
            industry experts for mentorship and enquiries.
          </p>
          <div className='gt-action-btns'>
            <button
              className='button gt-enrol-btn'
              data-bs-toggle='modal'
              data-bs-target='#enrolModal'
            >
              Enrol now <i className='fa-solid fa-arrow-right-long'></i>
            </button>
            <a href='mailto:contacts@krizbeatz.org'>
              <button className='button gt-contact-us'>
                Contact us <i className='fa-solid fa-chevron-right'></i>{' '}
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default GetStarted
