import { Routes, Route } from 'react-router-dom'
import Landing from './routes/landing/Landing'

import './App.css'
import Courses from './routes/courses/Courses'
import About from './routes/about/About'
import PricingPage from './routes/pricing/Pricing'
import Privacy from './routes/privacy/Privacy'
import Terms from './routes/terms/Terms'
import NotFound from './components/not-found/NotFound'
import Redirect from './routes/redirect/Redirect'
import Whatsapp from './components/whatsapp/Whatsapp'

function App () {
  return (
    <>
      <Whatsapp />
      <Routes>
        <Route path='*' element={<NotFound />} />
        <Route path='/' element={<Landing />} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/about' element={<About />} />
        <Route path='/pricing' element={<PricingPage />} />
        <Route path='/privacy-policy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/welcome' element={<Redirect />} />
      </Routes>
    </>
  )
}

export default App
