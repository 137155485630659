import React from 'react'
import './PricingHero.css'

const PricingHero = () => {
  return (
    <>
      <div className='custom-container pricing-page-container'>
        <h1>Pricing Details</h1>
        <p>Checkout Pricing & Benefits for each level available at Krizbeatz Music Academy</p>
      </div>
    </>
  )
}

export default PricingHero
