import React from "react";
import Faq from "react-faq-component";

import "./Faq.css";

const Faqs = () => {
  const data = {
    // title: "FAQ (How it works)",
    rows: [
      {
        title: "What will be covered in the class?",
        content:
          'The "Learn with Krizbeatz" production class will cover a wide range of topics related to music production, including beat making, mixing and mastering, sound design, and music theory. You will learn how to use digital audio workstations (DAWs) like FL Studio, Ableton Live, and Logic Pro, as well as a variety of software instruments and effects. The class will also cover the principles of music theory and how to apply them to your productions, as well as tips and tricks for creating professional-sounding tracks.',
      },
      {
        title:
          "Who is Krizbeatz and what is his background in music production?",
        content:
          "Krizbeatz is a music producer and DJ with a background in music production. and has a m masters in audio technology. He has worked with a number of notable artists around the globe and has received several awards for his work.",
      },
      {
        title:
          "Is this class suitable for beginners or is some prior knowledge required?",
        content:
          'The "Learn with Krizbeatz" class is suitable for both beginners and more experienced music producers. The class will cover a wide range of topics and will be structured in a way that is accessible to students at all levels.',
      },
      {
        title: "How long is the class and how will it be structured?",
        content:
          "The length of the class will depend on the specific offering. The class may be a single session or a series of sessions spread out over a period of time. It will be structured in a way that allows students to learn at their own pace, with a combination of lectures, demonstrations, and hands-on exercises. But the least is 6 weeks.",
      },
      {
        title:
          "Will students have the opportunity to work on their own projects during the class?",
        content:
          "Yes, students will have the opportunity to work on their own projects during the class. The instructor will provide guidance and support as needed to help students complete their projects.",
      },
      {
        title:
          "Is there any recommended software or equipment that students should have for the class?",
        content:
          "Students should have a computer and digital audio workstation (DAW) software, such as FL Studio, Ableton Live, or Logic Pro. The instructor may also recommend other software or equipment that students may find helpful.",
      },
      {
        title:
          "Will students receive a certificate of completion at the end of the class?",
        content:
          'This will depend on the specific offering of the class. Some "Learn with Krizbeatz" classes may offer a certificate of completion, while others may not.',
      },
      {
        title:
          "How can students contact the instructor if they have questions or need additional support during the class?",
        content:
          "Students can contact the instructor through email or through the class discussion forum. The instructor will be available to answer questions and provide support during the class.",
      },
      {
        title:
          "Are there any discounts available for students who sign up for multiple classes?",
        content:
          'This will depend on the specific offering of the class. Some "Learn with Krizbeatz" classes may offer discounts for students who sign up for multiple classes, while others may not.',
      },
      {
        title:
          "Is it possible to schedule a private session with Krizbeatz for one-on-one instruction?",
        content:
          'Yes'
      },
    ],
  };

  const styles = {
    bgColor: "transparent",
    titleTextColor: "black",
    rowTitleColor: "black",
    rowContentColor: "grey",
    arrowColor: "grey",
  };

  const config = {
    animate: true,
    tabFocus: true,
  };

  return (
    <section className="custom-container faq-container" id="faq">
      <h2 className="mb-5">Frequently asked questions</h2>
      <Faq data={data} styles={styles} config={config} />
    </section>
  );
};

export default Faqs;
