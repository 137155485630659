import React from 'react'
import { Link } from 'react-router-dom'
import Faqs from '../faq/Faq'
import OurClient from '../../components/our_client/OurClient'

import './AboutUs.css'
// import Whatsapp from './Whatsapp'
import AboutHero from '../../components/about_hero/AboutHero'
import AboutDetails from '../../components/about_details/AboutDetails'

const AboutUs = () => {
  return (
    <>
      <AboutHero />
      <div className='custom-container about-container'>
        <h3>About the Class</h3>
        <p>
          Welcome to Learn with Krizbeatz Music Academy ! We are a team of
          passionate and experienced musicians and educators dedicated to
          helping our students achieve their full potential and succeed in the
          music industry. This music academy was founded by Krizbeatz, a
          professional musician and experienced instructor with a proven track
          record of helping students of all skill levels reach their goals.
          Krizbeatz's extensive knowledge and hands-on approach to teaching
          makes him the perfect guide to help you take your music production
          skills to the next level. At Learn with Krizbeatz Academy, we offer a
          range of music production classes for students of all skill levels,
          from beginner to advanced. Whether you're just starting out and want
          to learn the basics, or you're an experienced producer looking to
          fine-tune your skills, we have a class for you. Our classes are
          tailored to meet your individual needs and goals, and our team is
          always available to answer your questions and offer support. We
          believe that with the right guidance and dedication, anyone can learn
          to make great music.{' '}
          <Link to='/book-class' className='learn-more'>
            Join us
          </Link>{' '}
          at Learn with Krizbeatz Music School and start your journey to
          becoming a successful music producer today!
        </p>
      </div>
      <div className="divider new-divider"></div>
      <AboutDetails />
      <OurClient />
      <Faqs />
      {/* <Whatsapp /> */}
    </>
  )
}

export default AboutUs
