import React from "react";
import { TypeAnimation } from "react-type-animation"; //eslint-disable-next-line
import heroImg from "../../assets/images/hero-img.png";

import "./Main.css";
// import Whatsapp from './Whatsapp'

const Main = () => {
  let name = ["Krizbeatz", "DrummerBoy", "WorldWave"];

  return (
    <div className="main custom-container">
      <div className="main-contents">
        <div className="lead-texts">
          <small className="ps-1">Become A Producer in 6 weeks</small>
          <h1 className="big-text">Learn directly from</h1>
          <TypeAnimation
            sequence={[
              name[0],
              3000,
              name[1],
              3000,
              name[2],
              2000,
              name[0],
              1000,
            ]}
            speed={1} // Custom Speed from 1-99 - Default Speed: 40
            wrapper="span" // Animation will be rendered as a <span>
            repeat={Infinity} // Repeat this Animation Sequence infinitely
          />
          <p className="mt-3">
            Start your journey to becoming a professional music producer like
            Krizbeatz. Get hands-on experience, industry insights and
            connections plus sample packs of loops, chords, melodies custom
            vocal samples, song starters percussion amongst many other benefits.
            Intensive 6 weeks of online coaching. All you need is a laptop and
            internet. Class size is almost full, click the button below to
            secure your spot.
          </p>
          <button
            className="button primary-btn mt-4 enrol-btn"
            data-bs-toggle="modal"
            data-bs-target="#enrolModal"
          >
            Enrol now <i className="fa-solid fa-arrow-right-long"></i>
          </button>
        </div>
        <div className="hero-img-container">
          <img src={heroImg} alt="" />
        </div>
      </div>
      {/* <Whatsapp /> */}
    </div>
  );
};

export default Main;
