import { useState } from 'react'
import logo from '../../assets/images/kma-logo.png'
import { Squeeze as Hamburger } from 'hamburger-react'
import { HashLink as Link } from 'react-router-hash-link';

import './Nav.css'

const Nav = () => {
  // Change nav style when scrolling
  const [color, setColor] = useState(false)
  const changeColor = () => {
    if (window.scrollY > 90) {
      setColor(true)
    } else {
      setColor(false)
    }
  }

  window.addEventListener('scroll', changeColor)

  //Navbar for mobile and tablet devices
  const [mobileNav, setMobileNavbar] = useState(false)
  const handleMobileNav = () => {
    setMobileNavbar(!mobileNav)
  }

  return (
    <nav className={color ? 'nav-scroll' : ''}>
      <div className='navigation'>
        <Link to='/'>
          <img src={logo} alt='Krizbeat logo' className='kma-logo'/>
        </Link>
        <div className={mobileNav ? 'nav-links open' : 'nav-links'}>
          <Link to='/courses'>Courses</Link>
          <Link to='/about'>About</Link>
          <Link to='/pricing'>Pricing</Link>
          <Link to='/#faq'>Faq</Link>
          <button className='button primary-btn enrol-btn' data-bs-toggle="modal" data-bs-target="#enrolModal">Enrol now</button>
        </div>
        <div className='toggle-nav-button' onClick={handleMobileNav}>
          <Hamburger size={25} />
        </div>
      </div>
    </nav>
  )
}

export default Nav
