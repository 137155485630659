import React from 'react'
import skeletonImg from '../../assets/images/skeleton-img.png'

import './AboutHero.css'

const AboutHero = () => {
  return (
    <>
      <div className='custom-container ab-hero-container'>
        <div className='a-hero-info'>
          <h1 className='mb-3'>About KMA.</h1>
          <p>
            KMA (Krizbeaz Music Academy) was founded by Krizbeatz, a
            professional musician and experienced instructor with a proven track
            record of helping students of all skill levels reach their goals.
            Krizbeatz's extensive knowledge and hands-on approach to teaching
            makes him the perfect guide to help you take your music production
            skills to the next level. At KMA, we offer a range of music
            production classes for students of all skill levels, from beginner
            to advanced.
          </p>
          <button
            className='button primary-btn co-enrol-btn mt-4'
            data-bs-toggle='modal'
            data-bs-target='#enrolModal'
          >
            Enrol now <i className='fa-solid fa-arrow-right-long ms-3'></i>
          </button>
        </div>
        <div className='hero-img'>
          <img src={skeletonImg} alt='' />
        </div>
      </div>
    </>
  )
}

export default AboutHero
