import React from 'react'
import clients from '../../components/client_data/client-data'

import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'

import './OurClient.css'

const OurClient = () => {
  return (
    <>
      <div className='custom-container client-container'>
        <h1>Our Client</h1>
        <p>
          Krizbeatz has worked with different companies creating sounds and
          Music worldwide.
        </p>
        <Swiper
          slidesPerView={4}
          spaceBetween={10}
          grabCursor={true}
          centeredSlides={true}
          autoplay={{ delay: 1000, disableOnInteraction: false }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50
            }
          }}
          pagination={false}
          modules={[Autoplay]}
          className='mt-5'
        >
          <div className='clients'>
            {clients.map(({ id, client }) => {
              return (
                <SwiperSlide className='client' key={id}>
                  <img
                    src={client}
                    alt='Clients & Brands Krizbeatz had work with'
                    className='client-images'
                  />
                </SwiperSlide>
              )
            })}
          </div>
        </Swiper>
      </div>
    </>
  )
}

export default OurClient
