import React from 'react'
import Footer from '../../components/footer/Footer'
import Modal from '../../components/modal/Modal'
import Nav from '../../components/navigation/Nav'
import Policies from '../../components/policies/Policies'
import PrivacyHero from '../../components/privacy_hero/PrivacyHero'

const Privacy = () => {
  return (
    <>
      <Nav />
      <PrivacyHero />
      <Policies />
      <Footer />
      <Modal />
    </>
  )
}

export default Privacy
