import React from 'react'

function Whatsapp() {
    const whatsappIcon = {
        fontSize: '31px',
        backgroundColor: '#25D366',
        color: 'white',
        position: 'fixed',
        right: '20px',
        bottom: '20px',
        padding: '15px 17px',
        borderRadius: '50%',
        cursor: 'pointer',
        zIndex: '3000'
      }
    
      return (
        <div className='whatsapp-container'>
          <a href='https://wa.me/message/Y4Z24JU374N4P1' target="_blank" rel="noreferrer">
            <i className='fa-brands fa-whatsapp' style={whatsappIcon}></i>
          </a>
        </div>
      )
}

export default Whatsapp