import React from 'react'

import './PrivacyHero.css'

const PrivacyHero = () => {
  return (
    <>
      <div className='custom-container privacy-policy-container'>
        <h1>Privacy Policy</h1>
        <p>
          Every user of this website must adhere to these policies which guide
          user activities while using this website.
        </p>
      </div>
    </>
  )
}

export default PrivacyHero
