import React from "react";

import "./PricingSection.css";

const PricingSection = () => {
  return (
    <>
      <div className="custom-container">
        <h1>Pricing</h1>
        <div className="pricing-lists mt-5">
          {/* Beginner */}
          <div className="beginner card p-4">
            <div className="card-title">
              <p>Beginner</p>
              <h1>
                800<span>usd</span>
              </h1>
            </div>

            <div className="card-benefit-content">
              <div className="card-benefit">
                <ul>
                  <li>Setting up your DAW FL</li>
                  <li>Beat Making</li>
                  <li>Drum Patterns For Afrobeats </li>
                  <li>Writing Catchy Melodies</li>
                  <li>Basic Beat Mixing</li>
                  <li>Sound Design</li>
                  <li>Recording And Editing</li>
                  <li>Tools To Improve Your Sound</li>
                  <li>(See more in courses tab)</li>
                  {/* <li>Art of Mixing (Basic)</li>
                <li>Recording & Editing</li>
                <li>Copyrights & Publishing</li>
                <li>Intro to Sound design</li>
                <li>Preparing environment for music making</li>
                <li className='cancel-benefit'>Sampling (In-depth)</li>
                <li className='cancel-benefit'>Music Composition</li>
                <li className='cancel-benefit'>Intro to Afrobeats Sampling</li>
                <li>One on One live feedback</li>
                <li className='cancel-benefit'>Mastering</li>
                <li>Acoustic treatment</li>
                <li className='cancel-benefit'>Creative prodution & remix</li>
                <li className='cancel-benefit'>Music industry basics</li>
                <li className='cancel-benefit'>Creative FX at work</li>
                <li className='cancel-benefit'>Music Production (Ableton)</li>
                <li className='cancel-benefit'>Sound design (In-depth)</li>
                <li className='cancel-benefit'>
                  Mastering for different medias{' '}
                </li>
                <li className='cancel-benefit'>Mastering in action</li>
                <li className='cancel-benefit'>A & R</li>
                <li className='cancel-benefit'>Planning for Business</li>
                <li className='cancel-benefit'>Professional Development</li> */}
                </ul>
              </div>
              <a href="https://payments.krizbeatzmusicacademy.com/#/">
                <button className="button enrol-btn-list">Enrol now</button>
              </a>
            </div>
          </div>

          {/* Intermediate */}
          <div className="intermediate card p-4">
            <div className="card-title">
              <p>Intermediate</p>
              <h1>
                1,800<span>usd</span>
              </h1>
            </div>
            <div className="card-benefit-content">
              <div className="card-benefit">
                <ul>
                  <li>Everything in Beginner plan</li>
                  <li>Setting Yourself Up For Success</li>
                  <li>The Goal Of A Music Producer</li>
                  <li>Understanding Sound</li>
                  <li>Setting Up Your Environment</li>
                  <li>How To Measure Sounds For Accuracy</li>
                  <li>Acoustic Treatment</li>
                  <li>How To Build Acoustic DIY</li>
                  <li>Mixing & Mastering (Start To Finish)</li>
                  <li>Get A Unique Mixing Template For You</li>
                  <li>Making Your Own Sample</li>
                  <li>Building Your Career In Music</li>
                  <li>(See more in courses tab)</li>
                  {/* <li>Copyrights & Publishing</li>
                <li>Intro to Sound design</li>
                <li>Preparing environment for music making</li>
                <li>Sampling (In-depth)</li>
                <li>Music Composition</li>
                <li>Intro to Afrobeats Sampling</li>
                <li>One on One live feedback</li>
                <li>Mastering</li>
                <li>Acoustic treatment</li>
                <li className="cancel-benefit">Creative prodution & remix</li>
                <li className="cancel-benefit">Music industry basics</li>
                <li className="cancel-benefit">Creative FX at work</li>
                <li className="cancel-benefit">Music Production (Ableton)</li>
                <li className="cancel-benefit">Sound design (In-depth)</li>
                <li className="cancel-benefit">
                  Mastering for different medias{" "}
                </li>
                <li className="cancel-benefit">Mastering in action</li>
                <li className="cancel-benefit">A & R</li>
                <li className="cancel-benefit">Planning for Business</li>
                <li className="cancel-benefit">Professional Development</li> */}
                </ul>
              </div>
              <a href="https://payments.krizbeatzmusicacademy.com/#/intermediate-full">
                <button className="button enrol-btn-list">Enrol now</button>
              </a>
            </div>
          </div>

          {/* Advanced */}
          <div className="advanced card p-4">
            <div className="card-title">
              <p>Advanced</p>
              <h1>
                2,800<span>usd</span>
              </h1>
            </div>
            <div className="card-benefit">
              <ul>
                <li>Everything in Intermediate</li>
                <li>Music Production (Ableton)</li>
                <li>Art of Mixing & Mastering</li>
                <li>Recording & Layering</li>
                <li>Copyrights & Publishing</li>
                <li>Preparing Environment For Music Making</li>
                <li>Sampling (In-depth)</li>
                <li>Music Composition</li>
                <li>Afrobeats Sampling</li>
                <li>Mastering Acoustic Treatment</li>
                <li>Creative Fx At Work</li>
                <li>Signing You Up With A Sync Licensing Company</li>
                <li>Relationship Between Picture And Sound</li>
                <li>Making You First Major $$$</li>
                <li>Making Remixes</li>
                <li>Live Demo</li>
                <li>Making Dj Mashups</li>
                <li>A & R Mastering</li>
                <li>Mastering in Action</li>
                <li>Mastering For Different Media</li>
                <li>Planning for Business</li>
                <li>Professional Development</li>
                <li>One On One Live Feedback </li>
                <li>3 Months Mentorship Post School </li>
              </ul>
            </div>
            <a href="https://payments.krizbeatzmusicacademy.com/#/advanced-full">
              <button className="button enrol-btn-list">Enrol now</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingSection;
