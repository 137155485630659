import CourseHero from "../../components/course_hero/CourseHero";
import Footer from "../../components//footer/Footer";
import Nav from "../../components/navigation/Nav";
import Modal from "../../components/modal/Modal";

const Courses = () => {
  return (
    <div className="loader">
      <div>
        <Nav />
        <CourseHero />
        <Footer />
        <Modal />
      </div>
    </div>
  );
};

export default Courses;
