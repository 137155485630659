import './AboutLanding.css'

import { Link } from 'react-router-dom'


const AboutLanding = () => {
  return (
    <>
      <div className='custom-container'>
        <div className='about-desc'>
          <p>
            Get ready to elevate you music production game! With Krizbeatz as
            your guide, you'll learn all the tricks of the trade and become a
            pro in no time. No matter your skill level, our tailored classes
            will help you reach your full potential. Don't miss out - book your
            session with Krizbeatz today and let's make some killer tracks
            together!
          </p>
          <Link to='/about'><button className="button ln-mr-btn">Learn more</button></Link>
        </div>
      </div>
    </>
  )
}

export default AboutLanding
