import React from 'react'

import './SubMainVid.css'

const SubMainVid = () => {

  return (
    <div className='custom-container submain-container'>
      <h1 className='mb-5'>What Krizbeatz has to say!</h1>
      <div className='video-container'>
        <iframe
          width='560'
          height='315'
          src='https://www.youtube.com/embed/Dw9Y3gbNX18'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;'
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default SubMainVid
