import React from 'react'
import elevateImg from '../../assets/images/cds.png'
import djImg from '../../assets/images/dj.png'
import recordingImg from '../../assets/images/mic.png'
import speakerImg from '../../assets/images/speaker.png'

import './AboutDetails.css'

const AboutDetails = () => {
  return (
    <>
      <div className='custom-container'>
        <div className='elevate flex-boxes'>
          <div className='elevate-info'>
            <h1>Elevate your Music!</h1>
            <p>
              Get ready to elevate you music production game! With Krizbeatz as
              your guide, you'll learn all the tricks of the trade and become a
              pro in no time.
            </p>
            <button
              className='button ln-mr-btn'
              data-bs-toggle='modal'
              data-bs-target='#enrolModal'
            >
              Enrol now <i className='fa-solid fa-arrow-right-long ms-3'></i>
            </button>
          </div>
          <div className='elevate-image'>
            <img src={elevateImg} alt='' className='detail-img' />
          </div>
        </div>
        <div className='divider new-divider off'></div>
        <div className='dj flex-boxes'>
          <div className='dj-image'>
            <img src={djImg} alt='' className='detail-img' />
          </div>
          <div className='dj-info'>
            <h1>Become a professional DJ!</h1>
            <p>
              Learn how to mix songs together even as a producer. Learn the in
              and out of disky jokey.
            </p>
            <button
              className='button ln-mr-btn'
              data-bs-toggle='modal'
              data-bs-target='#enrolModal'
            >
              Enrol now <i className='fa-solid fa-arrow-right-long ms-3'></i>
            </button>
          </div>
        </div>
        <div className='divider new-divider off'></div>
        <div className='recording flex-boxes'>
          <div className='recording-info'>
            <h1>Upgrade you sounds!</h1>
            <p>
              Upgrade your sounds when you learn from krizbeatz. Krizbeatz will
              teach how to record yourself like a professional.
            </p>
            <button
              className='button ln-mr-btn'
              data-bs-toggle='modal'
              data-bs-target='#enrolModal'
            >
              Enrol now <i className='fa-solid fa-arrow-right-long ms-3'></i>
            </button>
          </div>
          <div className='recording-image'>
            <img src={recordingImg} alt='' className='detail-img' />
          </div>
        </div>
        <div className='divider new-divider off'></div>
        <div className='speaker flex-boxes'>
          <div className='speaker-image'>
            <img src={speakerImg} alt='' className='detail-img' />
          </div>
          <div className='speaker-info'>
            <h1>Enhance your listening ability!</h1>
            <p>
              Listening and understanding different sounds is a skill every
              producer should have. With Krizbeatz, you will learn how to listen
              to sounds from different channels.
            </p>
            <button
              className='button ln-mr-btn'
              data-bs-toggle='modal'
              data-bs-target='#enrolModal'
            >
              Enrol now <i className='fa-solid fa-arrow-right-long ms-3'></i>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutDetails
